import React from "react"
import { graphql } from "gatsby"
import styles from "./page.module.scss"
import PageContent from "../components/PageContent/pageContent"

function Page(props) {
  const data = {
    ...props.data,
    uri: props.uri,
    language: props.language,
  }
  const pageStyles = `${styles.page}`
  // console.log("page data", data)

  return (
    <div className={pageStyles}>
      <PageContent data={data} />
    </div>
  )
}

export default Page

export const pageQuery = graphql`
  query pageContentQuery($id: String!) {
    options: wordpressAcfOptions {
      options {
        links {
          link_text
          link_url
          icon {
            localFile {
              childImageSharp {
                fluid(maxWidth: 50) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    wordpressPage(id: { eq: $id }) {
      id
      slug
      status
      title
      acf {
        contact_icon {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 3600) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        image_mobile {
          localFile {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        telephone
        email
        address {
          house_number
          street
          town
          country
        }
        headline_title {
          title
          title_mt
        }
        headline_subtitle {
          add_subtitle
          subtitle
          subtitle_mt
        }
        headline_content {
          add_content
          content
          content_mt
        }
        services {
          service_en
          service_mt
        }
        content_block {
          title {
            title
            title_mt
          }
          subtitle {
            add_subtitle
            subtitle
            subtitle_mt
          }
          image {
            add_image
            align_image
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
          icon {
            add_icon
            align_icon
            icon {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
          content {
            add_content
            content
            content_mt
          }
        }
      }
    }
  }
`
